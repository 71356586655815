import IdiotScoresActionTypes from './idiotScoresTypes';

const initialState = {
  requesting: false,
  successful: false,
  error: null,
  msg: '',
  liveScores: [],
};

const idiotScoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case IdiotScoresActionTypes.GET_IDIOTSCORES_START:
      return {
        ...state,
        requesting: true,
        successful: false,
        msg: { body: 'Fetching idiot scores..' },
      };
    case IdiotScoresActionTypes.GET_IDIOTSCORES_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        msg: { body: 'Successfully fetched idiot scores.' },
        liveScores: action.payload,
      };
    case IdiotScoresActionTypes.GET_IDIOTSCORES_FAILURE:
      return {
        ...state,
        requesting: false,
        successful: false,
        msg: { body: 'Error fetching idiot scores.' },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default idiotScoresReducer;
