import axios from 'axios';

// Redux Get Correct Scores Function

export const getCorrectScores = (picks) => {
  // Create final data object
  let finalScoresAndRecord = {};

  // Create array of objects containing historical data to attach to final data object
  finalScoresAndRecord.allTime = [
    {
      year: 2018,
      wins: 64,
      losses: 76,
    },
    {
      year: 2020,
      wins: 53,
      losses: 57,
    },
    {
      year: 2021,
      wins: 53,
      losses: 57,
    },
    {
      year: 2022,
      wins: 58,
      losses: 61,
    },
  ];

  // Create array that we'll push each weekly score object into. We map over original data.js set
  // and do calculations to object individual scores for each week as well as record and week
  let finalScores = [];

  // Create object to add up records for the entire year
  let finalRecord = {
    wins: 0,
    losses: 0,
    Geoff: 0,
    Chris: 0,
    Danny: 0,
    GroupPick: 0,
    weeks: picks.length,
  };

  // Map over entire picks array in data.js data set
  picks.map((weeklydata) => {
    // Create object that will house our weekly information
    let weeklyScores = {};

    // copy week and picks for that week into our weeklyScores object
    weeklyScores.week = weeklydata.week;
    weeklyScores.picks = weeklydata.pick;

    // Create object to add up scores for the week for each picker
    let pickerScores = {
      Geoff: 0,
      Chris: 0,
      Danny: 0,
      GroupPick: 0,
    };

    // map over each pick object within our picks array in data.js data set
    weeklydata.pick.map((picks) => {
      // if the pick is correct, add 1 to picker value in pickerScores object
      if (picks.correct) {
        pickerScores[picks.picker] = pickerScores[picks.picker] + 1;
      }
    });

    // Copy mapped over pickerScores object with week's scores to our weekly information object
    weeklyScores.pickerScores = pickerScores;

    // Add up all wins for the week
    const wins =
      pickerScores.Geoff +
      pickerScores.Chris +
      pickerScores.Danny +
      pickerScores.GroupPick;

    // Calculate losses
    const losses = 10 - wins;

    // Calculate record
    const record = `${wins} - ${losses}`;

    // Add total record to weekly information object
    weeklyScores.record = record;

    // Push each week's object to finalScores array
    finalScores.push(weeklyScores);

    // Add each weeks wins and losses to finalRecord object in order to get overall wins and losses for the year
    // finalRecord.map(picker => {

    // })
    finalRecord.wins = finalRecord.wins + wins;
    finalRecord.losses = finalRecord.losses + losses;

    // Add each weeks wins and losses to finalRecord object in order to get total wins and losses for each picker
    finalRecord.Geoff = finalRecord.Geoff + pickerScores.Geoff;
    finalRecord.Chris = finalRecord.Chris + pickerScores.Chris;
    finalRecord.Danny = finalRecord.Danny + pickerScores.Danny;
    finalRecord.GroupPick = finalRecord.GroupPick + pickerScores.GroupPick;
  });

  // Copy each weeks pick data for the year (allScores) to final data object
  finalScoresAndRecord.allScores = finalScores;

  // Sort so most recent week data is listed first
  finalScoresAndRecord.allScores.sort((x, y) => {
    return y.week - x.week;
  });

  // Copy total calculated record data to final data object
  finalScoresAndRecord.overallRecord = finalRecord;

  return finalScoresAndRecord;
};

// Get CFB Scores API Function

export const getScores = async () => {
  const p = 'https://glacial-savannah-83348.herokuapp.com/api/scores';
  //const p2 = 'https://for-entertainment-purposes-only.com/';
  const l = 'http://localhost:8000/api/scores';
  const scores = await axios.get(p);
  if (scores) {
    return scores.data.data.results;
  }
};

// Calculate Winners and Losers based on Livescores and Weekly Picks

export const calcWeeklyScores = (api, current, past) => {
  let comparedGames;
  let finalData = {
    all_time: [
      {
        year: 2018,
        wins: 64,
        losses: 76,
      },
      {
        year: 2020,
        wins: 53,
        losses: 57,
      },
      {
        year: 2021,
        wins: 53,
        losses: 57,
      },
      {
        year: 2022,
        wins: 0,
        losses: 0,
      },
    ],
    this_year: {},
    this_week: {
      week: current.week,
      week_wins: 0,
      week_losses: 0,
      scores: [
        {
          name: 'Geoff',
          games: [],
          wins: 0,
          losses: 0,
          form: [],
          yearly_wins: 0,
          yearly_losses: 0,
          img: 'http://www.petekehr.com/geoff.jpg',
        },
        {
          name: 'Chris',
          games: [],
          wins: 0,
          losses: 0,
          form: [],
          yearly_wins: 0,
          yearly_losses: 0,
          img: 'http://www.petekehr.com/chris.jpg',
        },
        {
          name: 'Danny',
          games: [],
          wins: 0,
          losses: 0,
          form: [],
          yearly_wins: 0,
          yearly_losses: 0,
          img: 'http://www.petekehr.com/danny.jpg',
        },
        {
          name: 'GroupPick',
          games: [],
          wins: 0,
          losses: 0,
          form: [],
          yearly_wins: 0,
          yearly_losses: 0,
        },
      ],
    },
    idiot_this_week: {
      week: current.week,
      week_wins: 0,
      week_losses: 0,
      scores: [
        {
          name: 'The Idiots',
          games: [],
          wins: 0,
          losses: 0,
          form: [],
          yearly_wins: 0,
          yearly_losses: 0,
          img: 'http://www.petekehr.com/idiot.jpg',
        },
      ],
    },
  };

  // Create object to add up scores for the week for each picker
  let pickerScores = {
    Geoff: 0,
    Chris: 0,
    Danny: 0,
    GroupPick: 0,
  };

  past.map((weekly_picks) => {
    weekly_picks.pick.map((p) => {
      finalData.this_week.scores.map((pickers) => {
        if (p.picker === pickers.name) {
          if (p.correct === true) {
            pickers.yearly_wins = pickers.yearly_wins + 1;
            finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
            pickers.form.unshift({
              correct: p.correct,
              week: weekly_picks.week,
              team: p[p.winner],
            });
          } else {
            pickers.yearly_losses = pickers.yearly_losses + 1;
            finalData.all_time[2].losses = finalData.all_time[2].losses + 1;
            pickers.form.unshift({
              correct: p.correct,
              week: weekly_picks.week,
              team: p[p.winner],
            });
          }
        }
      });
      finalData.idiot_this_week.scores.map((pickers) => {
        if (p.picker === pickers.name) {
          if (p.correct === true) {
            pickers.yearly_wins = pickers.yearly_wins + 1;
            finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
            pickers.form.unshift({
              correct: p.correct,
              week: weekly_picks.week,
              team: p[p.winner],
            });
          } else {
            pickers.yearly_losses = pickers.yearly_losses + 1;
            finalData.all_time[2].losses = finalData.all_time[2].losses + 1;
            pickers.form.unshift({
              correct: p.correct,
              week: weekly_picks.week,
              team: p[p.winner],
            });
          }
        }
      });
    });
  });

  current.pick.map((picks) => {
    let liveData = {
      spread: 0,
      home: '',
      away: '',
      score: {
        away: 0,
        awayPeriods: [],
        home: 0,
        homePeriods: [],
      },
      picker: '',
      cover: false,
      schedule: '',
    };
    api.map((scores) => {
      if (scores.teams.home.team === picks.home) {
        if (scores.status === 'final' || scores.status === 'in progress') {
          liveData.spread = picks.spread;
          liveData.home = picks.home;
          liveData.winner = picks.winner;
          liveData.away = picks.away;
          liveData.score = scores.scoreboard;
          liveData.picker = picks.picker;
          liveData.status = scores.status;
          pickerScores.in_play = pickerScores.in_play + 1;

          if (picks.winner === 'home') {
            const didCover = liveData.score.score.home + liveData.spread;
            if (didCover > liveData.score.score.away) {
              liveData.cover = true;
              finalData.this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.wins = pickers.wins + 1;
                  pickers.yearly_wins = pickers.yearly_wins + 1;
                  finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
                  finalData.this_week.week_wins =
                    finalData.this_week.week_wins + 1;
                }
              });
              finalData.idiot_this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.wins = pickers.wins + 1;
                  pickers.yearly_wins = pickers.yearly_wins + 1;
                  finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
                  finalData.idiot_this_week.week_wins =
                    finalData.idiot_this_week.week_wins + 1;
                }
              });
            } else {
              liveData.cover = false;
              finalData.this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.losses = pickers.losses + 1;
                  pickers.yearly_losses = pickers.yearly_losses + 1;
                  finalData.all_time[2].losses =
                    finalData.all_time[2].losses + 1;
                  finalData.this_week.week_losses =
                    finalData.this_week.week_losses + 1;
                }
              });
              finalData.idiot_this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.losses = pickers.losses + 1;
                  pickers.yearly_losses = pickers.yearly_losses + 1;
                  finalData.all_time[2].losses =
                    finalData.all_time[2].losses + 1;
                  finalData.idiot_this_week.week_losses =
                    finalData.idiot_this_week.week_losses + 1;
                }
              });
            }
          }

          if (picks.winner === 'away') {
            const didCover = liveData.score.score.away + liveData.spread;
            if (didCover > liveData.score.score.home) {
              liveData.cover = true;
              finalData.this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.wins = pickers.wins + 1;
                  pickers.yearly_wins = pickers.yearly_wins + 1;
                  finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
                  finalData.this_week.week_wins =
                    finalData.this_week.week_wins + 1;
                }
              });
              finalData.idiot_this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.wins = pickers.wins + 1;
                  pickers.yearly_wins = pickers.yearly_wins + 1;
                  finalData.all_time[2].wins = finalData.all_time[2].wins + 1;
                  finalData.idiot_this_week.week_wins =
                    finalData.idiot_this_week.week_wins + 1;
                }
              });
            } else {
              liveData.cover = false;
              finalData.this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.losses = pickers.losses + 1;
                  pickers.yearly_losses = pickers.yearly_losses + 1;
                  finalData.all_time[2].losses =
                    finalData.all_time[2].losses + 1;
                  finalData.this_week.week_losses =
                    finalData.this_week.week_losses + 1;
                }
              });
              finalData.idiot_this_week.scores.map((pickers) => {
                if (picks.picker === pickers.name) {
                  pickers.losses = pickers.losses + 1;
                  pickers.yearly_losses = pickers.yearly_losses + 1;
                  finalData.all_time[2].losses =
                    finalData.all_time[2].losses + 1;
                  finalData.idiot_this_week.week_losses =
                    finalData.idiot_this_week.week_losses + 1;
                }
              });
            }
          }
        } else {
          liveData.spread = picks.spread;
          liveData.home = picks.home;
          liveData.away = picks.away;
          liveData.name = picks.name;
          liveData.reason = picks.reason;
          liveData.schedule = scores.schedule;
          liveData.winner = picks.winner;
          liveData.score = {
            score: {
              home: 0,
              away: 0,
            },
          };
          liveData.picker = picks.picker;
          liveData.status = scores.status;
        }
      }
    });
    finalData.this_week.scores.map((pickers) => {
      if (picks.picker === pickers.name) {
        pickers.games.push(liveData);
      }
    });
    finalData.idiot_this_week.scores.map((pickers) => {
      if (picks.picker === pickers.name) {
        pickers.games.push(liveData);
      }
    });
  });

  // finalData.idiot_this_week.scores[0].games = comparedGames;

  // finalData.this_week.scores.map((expert_games) => {
  //   expert_games.games.map((expert_game) => {
  //     console.log('Expert home picks:' + expert_game.home);
  //     comparedGames.map((idiot_game) => {
  //       console.log('Comparing');
  //       if (expert_game.home === idiot_game.home) {
  //         expert_game.common = true;
  //         idiot_game.common = true;
  //       } else {
  //         expert_game.common = false;
  //         idiot_game.common = false;
  //       }
  //     });
  //   });
  // });

  return finalData;
};

// Combine live scores and historical data function

export const combineScores = (live, historical) => {
  console.log(live.liveScores.wins);
  historical.overallRecord.wins =
    historical.overallRecord.wins + live.liveScores.wins;
  historical.overallRecord.losses =
    historical.overallRecord.losses + live.liveScores.losses;
  return historical;
};

// Get Fixtures Function

export const getFixtures = async () => {
  const fixtures = await axios.get('http://localhost:5000/fixtures');
  console.log('getFixtures function fired');
  console.log(fixtures.data.data);

  if (fixtures) {
    return fixtures.data.data;
  }
};

// Get Results Function

export const getResults = async () => {
  const results = await axios.get('http://localhost:5000/results');
  console.log('getResults function fired');
  console.log(results.data.data.match);

  if (results) {
    return results.data.data.match;
  }
};

// Get Live Fixtures Function

export const getLive = async () => {
  const live = await axios.get('http://localhost:5000/live');
  console.log('getLive function fired');
  console.log(live.data.data.match);

  if (live) {
    return live.data.data.match;
  }
};

// Get Field Function

export const getField = async () => {
  const live = await axios.get('http://localhost:5000/field');
  console.log('getLive function fired');
  console.log(live.data.data.results);

  if (live) {
    return live.data.data.results;
  }
};

// Get Leaderboard Function

export const getLeaderboard = async () => {
  const leaderboard = await axios.get('http://localhost:5000/leaderboard');
  console.log('getLeaderboard function fired');
  console.log(leaderboard.data.data.results);

  if (leaderboard) {
    return leaderboard.data.data.results;
  }
};

// Login Function

export const getToken = async (username, password) => {
  // Headers
  let config = {
    'Content-Type': 'application/json',
  };
  const body = {
    username: username,
    password: password,
  };
  const token = await axios.post(
    'http://localhost:5000/api/auth/login',
    body,
    config
  );

  return token.data.token;
};

// Get Logged In User with Token

export const getLoggedInUser = async (token) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getUser = await axios.get('http://localhost:5000/api/auth/me', options);
  //console.log(getUser.data.data);
  return getUser.data.data;
};

// Set Auth Token

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
    localStorage.setItem('token', token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

// Set User Id

export const setId = (id) => {
  localStorage.setItem('id', id);
};

// Get User Id

export const getId = () => {
  return localStorage.getItem('id');
};

// Log out user

export const logout = async () => {
  const handleLogout = await axios.get('http://localhost:5000/api/auth/logout');
  return handleLogout;
};

// Clear token from localStorage

export const handleLocalStorage = () => {
  console.log(localStorage.token);
  localStorage.removeItem('token');
  localStorage.removeItem('authed');
  localStorage.removeItem('id');
};

// Get Roster from User ID

export const getStarters = async (id) => {
  const token = localStorage.token;
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const starters = await axios.get(
    `http://localhost:5000/api/auth/${id}/starters`,
    options
  );
  return starters.data.data;
};

export const setAuthenticated = () => {
  localStorage.setItem('authed', true);
};

export const getInPlay = async () => {
  const token = localStorage.token;
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const inplay = await axios.get(`http://localhost:5000/api/starters`, options);
  return inplay.data.data;
};

export const updateStarters = async (startersToUpdate) => {
  const token = localStorage.token;

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    startersToUpdate,
  };
  const starters = await axios.post(
    `http://localhost:5000/api/starters`,
    options,
    body
  );
  return starters.data.data;
};

export const calculateScore = (position) => {
  let score;
  position === 1
    ? (score = 20)
    : position >= 2 && position <= 3
    ? (score = 15)
    : position >= 4 && position <= 5
    ? (score = 10)
    : position >= 6 && position <= 10
    ? (score = 6)
    : position >= 11 && position <= 20
    ? (score = 4)
    : position >= 21 && position <= 30
    ? (score = 3)
    : position >= 31 && position <= 40
    ? (score = 2)
    : position >= 41 && position <= 50
    ? (score = 1)
    : (score = 0);
  return score;
};

export const compareScores = (r, l) => {
  let finalScores = [];
  r.map((playerA) => {
    l.map((playerB) => {
      if (
        playerA.last === playerB.last_name &&
        playerA.first === playerB.first_name
      ) {
        let scoreObject = {};

        scoreObject.first_name = playerB.first_name;
        scoreObject.last_name = playerB.last_name;
        scoreObject.score = [];
        scoreObject.position = playerB.position;
        scoreObject.totalToPar = playerB.total_to_par;
        playerB.rounds.map((findPosition) => {
          let roundScores = {};
          let multiplier = findPosition.round_number;
          roundScores.round = findPosition.round_number;
          roundScores.position = findPosition.position_round;
          roundScores.toPar = findPosition.total_to_par;
          roundScores.points = calculateScore(findPosition.position_round);
          scoreObject.score.push(roundScores);
        });
        scoreObject.totalScore = 0;
        scoreObject.score.map((score) => {
          scoreObject.totalScore += score.points;
        });
        // finalScores.data.sort((x, y) => {
        // 	return y.totalScore - x.totalScore;
        // });
        finalScores.push(scoreObject);
      }
    });
  });
  return finalScores;
};

export const compareAllScores = (r, l) => {
  let finalScores = [];
  r.map((allplayers) => {
    let userObject = {};
    let userData = [];
    userObject.user = allplayers.user.username;
    allplayers.starters[0].roster.map((playerA) => {
      l.map((playerB) => {
        if (playerA.last === playerB.last_name) {
          let scoreObject = {};
          scoreObject.first_name = playerB.first_name;
          scoreObject.last_name = playerB.last_name;
          scoreObject.score = [];
          scoreObject.position = playerB.position;
          scoreObject.totalToPar = playerB.total_to_par;
          playerB.rounds.map((findPosition) => {
            let roundScores = {};
            let multiplier =
              findPosition.round_number >= 1 && findPosition.round_number <= 2
                ? 1
                : 2;
            roundScores.round = findPosition.round_number;
            roundScores.position = findPosition.position_round;
            roundScores.toPar = findPosition.total_to_par;
            roundScores.points =
              calculateScore(findPosition.position_round) * multiplier;
            scoreObject.score.push(roundScores);
          });
          scoreObject.totalScore = 0;
          scoreObject.score.map((score) => {
            scoreObject.totalScore += score.points;
          });
          userData.push(scoreObject);
        }
      });
    });
    userObject.data = userData;
    userObject.data.sort((x, y) => {
      return y.totalScore - x.totalScore;
    });

    finalScores.push(userObject);
  });
  console.log(finalScores);
  return finalScores;
};
