import CorrectScoresActionTypes from './correctScoresTypes';

const initialState = {
	requesting: false,
	successful: false,
	error: null,
	msg: '',
	correctScores: []
};

const correctScoresReducer = (state = initialState, action) => {
	switch (action.type) {
		case CorrectScoresActionTypes.GET_CORRECTSCORES_START:
			return {
				...state,
				requesting: true,
				successful: false,
				msg: { body: 'Calculating correct scores..' }
			};
		case CorrectScoresActionTypes.GET_CORRECTSCORES_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				msg: { body: 'Successfully fetched calculated scores.' },
				correctScores: action.payload
			};
		case CorrectScoresActionTypes.GET_CORRECTSCORES_FAILURE:
			return {
				...state,
				requesting: false,
				successful: false,
				msg: { body: 'Error fetching calculated scores.' },
				error: action.payload
			};
		default:
			return state;
	}
};

export default correctScoresReducer;
