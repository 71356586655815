import React from 'react';
import { Flex, Box, Avatar, Stack, Spacer } from '@chakra-ui/react';
import { PickerForm } from './PickerForm';

export const PickerHeader = ({ pickers }) => {
	const setRecordAndPercent = (wins, losses) => {
		const games = wins + losses;
		const percentage = (wins / games) * 100;
		return percentage.toFixed(1);
	};

	return (
		<Flex>
			<Box>
				{pickers.name === 'GroupPick' ? (
					<Avatar
						name='Group'
						src='https://bit.ly/broken-link'
						w='50px'
						h='50px'
					/>
				) : (
					<Avatar src={pickers.img} w='50px' h='50px' />
				)}
			</Box>
			<Box className='main-out-of-3'>
				<Stack>
					<Box>
						{pickers.wins}/{pickers.wins + pickers.losses}
					</Box>
					<Box className='main-on-the-year'>
						<PickerForm form={pickers.form} />
					</Box>
				</Stack>
			</Box>
			<Spacer />
			<Box marginRight='5vw'>
				<Stack spacing={2}>
					<Box className='main-on-the-year'>
						<span className='purple'>
							{setRecordAndPercent(pickers.yearly_wins, pickers.yearly_losses)}%
						</span>{' '}
						Win Rate
					</Box>
					<Box className='main-on-the-year'>
						<span className='purple'>
							{pickers.yearly_wins}-{pickers.yearly_losses}
						</span>{' '}
						Record
					</Box>
				</Stack>
			</Box>
		</Flex>
	);
};
