import React from 'react';
import { Box, Center, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AllTime } from '../Live/AllTime';
import GlobalPickerComponent from '../Live/GlobalPickerComponent';

const DataTable = ({ liveScores, idiotScores }) => {
  DataTable.propTypes = {
    liveScores: PropTypes.object.isRequired,
    idiotScores: PropTypes.object.isRequired,
  };

  const { all_time } = liveScores.liveScores;
  return (
    <Stack>
      <Box>
        <GlobalPickerComponent />
      </Box>
      <Box>
        <AllTime record={all_time} />
      </Box>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  liveScores: state.liveScores,
  idiotScores: state.idiotScores,
});

export default connect(mapStateToProps)(DataTable);
