import { combineReducers, createStore, applyMiddleware } from 'redux';
import {
  correctScoresReducer,
  liveScoresReducer,
  idiotScoresReducer,
} from '../redux/reducers/index';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootSaga from '../redux/root-saga';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const dotenv = require('dotenv');

// Load env vars
dotenv.config({ path: './config/config.env' });

export const history = createBrowserHistory();

const reducer = () =>
  combineReducers({
    router: connectRouter(history),
    correctScores: correctScoresReducer,
    liveScores: liveScoresReducer,
    idiotScores: idiotScoresReducer,
  });

const sagaMiddleware = createSagaMiddleware();
const reduxRouterMiddleware = routerMiddleware(history);

const middlewares = [sagaMiddleware, reduxRouterMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = createStore(
  reducer(history),
  applyMiddleware(...middlewares)
);

sagaMiddleware.run(rootSaga);

export default store;
