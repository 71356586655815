import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
	GetLiveScoresFailure,
	GetLiveScoresSuccess
} from './liveScoresActions';
import LiveScoresActionTypes from './liveScoresTypes';
import { getScores, calcWeeklyScores, combineScores } from '../utils/utils';
import { livepicks, picks } from '../../data/data';

export function* FetchLiveScoresAsync() {
	yield console.log('FetchLiveScoresAsync function has fired.');
	try {
		const scores = yield call(getScores);
		console.log(scores);
		const calculatedScore = yield call(
			calcWeeklyScores,
			scores,
			livepicks,
			picks
		);
		yield put(GetLiveScoresSuccess(calculatedScore));
	} catch (e) {
		yield put(GetLiveScoresFailure(e));
	}
}

export function* OnFetchLiveScoresStart() {
	yield takeLatest(
		LiveScoresActionTypes.GET_LIVESCORES_START,
		FetchLiveScoresAsync
	);
	yield console.log('onFetchLiveScoresStart GET_LIVESCORES_START has fired.');
}

export function* liveScoresSagas() {
	yield all([call(OnFetchLiveScoresStart)]);
}
