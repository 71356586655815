import LiveScoresActionTypes from './liveScoresTypes';

// Handle Field

export const GetLiveScoresStart = () => ({
	type: LiveScoresActionTypes.GET_LIVESCORES_START
});

export const GetLiveScoresSuccess = (field) => ({
	type: LiveScoresActionTypes.GET_LIVESCORES_SUCCESS,
	payload: field
});

export const GetLiveScoresFailure = (e) => ({
	type: LiveScoresActionTypes.GET_LIVESCORES_FAILURE,
	payload: e
});
