import React from 'react';
import {
  Flex,
  Spacer,
  Stack,
  Box,
  Divider,
  Tag,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { ImCross, ImClock } from 'react-icons/im';
import { RiQuestionnaireFill } from 'react-icons/ri';
import moment from 'moment';

export const PickerGames = ({ games }) => {
  return (
    <Box>
      {games.map((game, id) => (
        <Box key={id}>
          <Flex width='95%'>
            <Box
              className='main-icon-center'
              bg={
                game.cover == true
                  ? '#83F45B'
                  : (game.cover == false && game.status == 'final') ||
                    (game.cover == false && game.status == 'in progress')
                  ? '#FC2D2D'
                  : game.cover == false && game.status == 'scheduled'
                  ? '#64BDFF'
                  : '#D7D7D7'
              }
              width='20%'
            >
              <Box className='main-icon'>
                {game.cover == true ? (
                  <FaCheck color='#41BB17' size='2em' />
                ) : (game.cover == false && game.status == 'final') ||
                  (game.cover == false && game.status == 'in progress') ? (
                  <ImCross color='#B70606' size='2em' />
                ) : game.cover == false && game.status == 'scheduled' ? (
                  <ImClock color='#1068B9' size='2em' />
                ) : null}
              </Box>
            </Box>
            <Box width='80%'>
              <Stack
                marginLeft='10px'
                marginTop='10px'
                spacing={2}
                paddingBottom='10px'
              >
                <Flex>
                  <Box
                    className={
                      game.status === 'in progress'
                        ? 'main-status blink_me'
                        : 'main-status'
                    }
                  >
                    {game.status === 'in progress' ? (
                      <span className='red'>In progress</span>
                    ) : game.status === 'final' ? (
                      <span className='orange'>Finished</span>
                    ) : game.status === 'scheduled' ? (
                      <span className='blue'>Scheduled</span>
                    ) : null}
                  </Box>
                  <Spacer />
                  <Box className='main-status'>
                    {game.status === 'final'
                      ? 'Final'
                      : game.status === 'scheduled'
                      ? moment(game.schedule.date).format('ddd h:mm A')
                      : game.status === 'in progress'
                      ? `Q${game.score.currentPeriod}` +
                        ' ' +
                        game.score.periodTimeRemaining
                      : null}
                  </Box>
                </Flex>
                <Stack spacing={-1}>
                  <Box className='main-score'>
                    <Flex>
                      <Box>{game.away}</Box>
                      <Spacer />
                      <Box>{game.score.score.away}</Box>
                    </Flex>
                  </Box>
                  <Box className='main-score'>
                    <Flex>
                      <Box>{game.home}</Box>
                      <Spacer />
                      <Box>{game.score.score.home}</Box>
                    </Flex>
                  </Box>
                </Stack>
                <Box className='main-cover'>
                  <Box>
                    {game.picker == 'GroupPick' ? 'The group' : game.picker}{' '}
                    took {game[game.winner]}{' '}
                    {Math.sign(game.spread) == 1 ? '+' : null}
                    {game.spread}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Flex>
          <Box width='95%'>
            <Divider borderColor='black' borderWidth='1' opacity='1' />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
