import './App.css';
import Home from './components/Home';
import { Provider } from 'react-redux';
import store, { history } from './components/store';
import { Box } from '@chakra-ui/react';
import { ConnectedRouter } from 'connected-react-router';

function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Box className='global'>
					<Home />
				</Box>
			</ConnectedRouter>
		</Provider>
	);
}

export default App;
