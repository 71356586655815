import { all, call } from 'redux-saga/effects';

// Import all sagas
import { correctScoresSagas } from './correctScores/correctScoresSagas';
import { liveScoresSagas } from './liveScores/liveScoresSagas';
import { idiotScoresSagas } from './idiotScores/idiotScoresSagas';

export default function* rootSaga() {
  yield all([
    call(correctScoresSagas),
    call(liveScoresSagas),
    call(idiotScoresSagas),
  ]);
}
