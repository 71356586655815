import React, { useEffect } from 'react';
import { Navbar } from './Navbar/Navbar';

import { PrivateRoute } from './PrivateRoute';
import { connect } from 'react-redux';
import { GetCorrectScoresStart } from '../redux/correctScores/correctScoresActions';
import { GetLiveScoresStart } from '../redux/liveScores/liveScoresActions';
import { GetIdiotScoresStart } from '../redux/idiotScores/idiotScoresActions';
import PropTypes from 'prop-types';
import DataTable from './DataTable/DataTable';
import { SkeletonCircle, SkeletonText, Center, Box } from '@chakra-ui/react';

const Home = ({
  GetCorrectScoresStart,
  correctScores,
  GetLiveScoresStart,
  liveScores,
  GetIdiotScoresStart,
  idiotScores,
}) => {
  useEffect(() => {
    //GetCorrectScoresStart();
    GetLiveScoresStart();
    GetIdiotScoresStart();
  }, []);

  Home.propTypes = {
    //GetCorrectScoresStart: PropTypes.func.isRequired,
    //correctScores: PropTypes.object.isRequired,
    GetLiveScoresStart: PropTypes.func.isRequired,
    liveScores: PropTypes.object.isRequired,
    idiotScores: PropTypes.object.isRequired,
  };

  const { successful } = liveScores;
  const idiot_successful = idiotScores.successful;

  return (
    <div>
      <Navbar />
      {!successful && !idiot_successful ? (
        <Box padding='6' bg='white'>
          <SkeletonCircle size='10' />
          <SkeletonText mt='4' noOfLines={4} spacing='4' />
        </Box>
      ) : (
        <DataTable />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  //correctScores: state.correctScores,
  liveScores: state.liveScores,
  idiotScores: state.idiotScores,
});

export default connect(mapStateToProps, {
  //GetCorrectScoresStart,
  GetLiveScoresStart,
  GetIdiotScoresStart,
})(Home);
