import IdiotScoresActionTypes from './idiotScoresTypes';

// Handle Field

export const GetIdiotScoresStart = () => ({
  type: IdiotScoresActionTypes.GET_IDIOTSCORES_START,
});

export const GetIdiotScoresSuccess = (field) => ({
  type: IdiotScoresActionTypes.GET_IDIOTSCORES_SUCCESS,
  payload: field,
});

export const GetIdiotScoresFailure = (e) => ({
  type: IdiotScoresActionTypes.GET_IDIOTSCORES_FAILURE,
  payload: e,
});
