export const picks = [
  {
    week: 1,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 2,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 3,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 4,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 5,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 6,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 7,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 8,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 9,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 10,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 12,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 11,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Geoff',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Chris',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'Danny',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'GroupPick',
        conference: '',
        correct: true,
      },
    ],
  },
];

export const livepicks = {
  week: 13,
  pick: [
    {
      home: 'Arkansas',
      away: 'Missouri',
      winner: 'away',
      spread: -7.5,
      picker: 'Chris',
      conference: '',
    },
    {
      home: 'Oklahoma State',
      away: 'BYU',
      winner: 'home',
      spread: -17.5,
      picker: 'Chris',
      conference: '',
    },
    {
      home: 'Nebraska',
      away: 'Iowa',
      winner: 'away',
      spread: 1.5,
      picker: 'Chris',
      conference: '',
    },
    {
      home: 'Mississippi State',
      away: 'Mississippi',
      winner: 'away',
      spread: -11.5,
      picker: 'Danny',
      conference: '',
    },
    {
      home: 'Arizona State',
      away: 'Arizona',
      winner: 'away',
      spread: -11.5,
      picker: 'Danny',
      conference: '',
    },
    {
      home: 'Virginia',
      away: 'Virginia Tech',
      winner: 'away',
      spread: -2.5,
      picker: 'Danny',
      conference: '',
    },
    {
      home: 'UTEP',
      away: 'Liberty',
      winner: 'away',
      spread: -16.5,
      picker: 'Geoff',
      conference: '',
    },
    {
      home: 'Cincinnati',
      away: 'Kansas',
      winner: 'away',
      spread: -5.5,
      picker: 'Geoff',
      conference: '',
    },

    {
      home: 'Georgia Tech',
      away: 'Georgia',
      winner: 'away',
      spread: -23.5,
      picker: 'Geoff',
      conference: '',
    },
    {
      home: 'Auburn',
      away: 'Alabama',
      winner: 'away',
      spread: -14.5,
      picker: 'GroupPick',
      conference: '',
    },
  ],
};

export const idiotpicks = [
  {
    week: 6,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 7,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 8,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 9,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
    ],
  },
  {
    week: 10,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 11,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
    ],
  },
  {
    week: 12,
    pick: [
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: true,
      },
      {
        home: '',
        away: '',
        winner: '',
        spread: 0,
        picker: 'The Idiots',
        conference: '',
        correct: false,
      },
    ],
  },
];

export const idiotlivepicks = {
  week: 13,
  pick: [
    {
      home: 'Sam Houston State',
      away: 'Middle Tennessee',
      winner: 'away',
      spread: -3.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Internet friend when I was 16 was from Tulsa',
      name: 'Pete',
    },
    {
      home: 'Massachusetts',
      away: 'Connecticut',
      winner: 'home',
      spread: 2.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Team sounds like the chicken company lol',
      name: 'Pete',
    },
    {
      home: 'Georgia Tech',
      away: 'Georgia',
      winner: 'away',
      spread: -23.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Picking teams with dudes names in them',
      name: 'Mike',
    },
    {
      home: 'Appalachian State',
      away: 'Georgia Southern',
      winner: 'away',
      spread: 8.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Picking teams with dudes names in them',
      name: 'Mike',
    },
    {
      home: 'Boise State',
      away: 'Air Force',
      winner: 'home',
      spread: 6.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Picking teams with dudes names in them',
      name: 'Mike',
    },
    {
      home: 'NC State',
      away: 'North Carolina',
      winner: 'away',
      spread: -2.5,
      picker: 'The Idiots',
      reason: 'Picking teams with dudes names in them',
      name: 'Mike',
    },
    {
      home: 'Mississippi State',
      away: 'Mississippi',
      winner: 'home',
      spread: 11.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Fans that smell like corndogs',
      name: 'Patrick',
    },
    {
      home: 'Oregon',
      away: 'Oregon State',
      winner: 'away',
      spread: 13.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Fans that smell like corndogs',
      name: 'Patrick',
    },

    {
      home: 'San Diego State',
      away: 'Fresno State',
      winner: 'away',
      spread: -5.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Nicky thought their fans smelled like corndogs',
      name: 'Alex',
    },
    {
      home: 'South Florida',
      away: 'Charlotte',
      winner: 'away',
      spread: 5.5,
      picker: 'The Idiots',
      conference: '',
      reason: 'Just vibes',
      name: 'Joe',
    },
  ],
};
