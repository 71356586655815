import CorrectScoresActionTypes from './correctScoresTypes';

// Handle Correct Scores

export const GetCorrectScoresStart = (data) => ({
	type: CorrectScoresActionTypes.GET_CORRECTSCORES_START,
	payload: data
});

export const GetCorrectScoresSuccess = (calculated_data) => ({
	type: CorrectScoresActionTypes.GET_CORRECTSCORES_SUCCESS,
	payload: calculated_data
});

export const GetCorrectScoresFailure = (e) => ({
	type: CorrectScoresActionTypes.GET_CORRECTSCORES_FAILURE,
	payload: e
});
