import React from 'react';
import {
  Stack,
  Box,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PickerData } from './PickerData';
import { IdiotPickerData } from './IdiotPickerData';
import { PickerHeader } from './PickerHeader';
import { WeeklyRecord } from './WeeklyRecord';

const GlobalPickerComponent = ({ liveScores, idiotScores }) => {
  GlobalPickerComponent.propTypes = {
    liveScores: PropTypes.object.isRequired,
    idiotScores: PropTypes.object.isRequired,
  };

  const { this_week } = liveScores.liveScores;
  const { idiot_this_week } = idiotScores.liveScores;

  return (
    <Box>
      {this_week && idiot_this_week ? (
        <Stack>
          <Box>
            <WeeklyRecord
              week={this_week.week}
              wins={this_week.week_wins}
              losses={this_week.week_losses}
              scores={this_week.scores}
            />
          </Box>
          <Box marginTop='10px'>
            <Tabs isFitted colorScheme='Gray 900' size='lg'>
              <TabList mb='1em' className='purple main-tabs'>
                <Tab>Experts</Tab>
                <Tab>Idiots</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box>
                    <PickerData scores={this_week.scores} />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <IdiotPickerData
                    scores={idiot_this_week.scores}
                    week={idiot_this_week.week}
                    wins={idiot_this_week.week_wins}
                    losses={idiot_this_week.week_losses}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  liveScores: state.liveScores,
  idiotScores: state.idiotScores,
});

export default connect(mapStateToProps)(GlobalPickerComponent);
