import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GetIdiotScoresFailure,
  GetIdiotScoresSuccess,
} from './idiotScoresActions';
import IdiotScoresActionTypes from './idiotScoresTypes';
import { getScores, calcWeeklyScores, combineScores } from '../utils/utils';
import { livepicks, picks, idiotlivepicks, idiotpicks } from '../../data/data';

export function* FetchIdiotScoresAsync() {
  yield console.log('FetchIdiotScoresAsync function has fired.');
  try {
    const scores = yield call(getScores);
    console.log(scores);
    const calculatedScore = yield call(
      calcWeeklyScores,
      scores,
      idiotlivepicks,
      idiotpicks
    );
    yield put(GetIdiotScoresSuccess(calculatedScore));
  } catch (e) {
    yield put(GetIdiotScoresFailure(e));
  }
}

export function* OnFetchIdiotScoresStart() {
  yield takeLatest(
    IdiotScoresActionTypes.GET_IDIOTSCORES_START,
    FetchIdiotScoresAsync
  );
  yield console.log('onFetchIdiotScoresStart GET_IDIOTSCORES_START has fired.');
}

export function* idiotScoresSagas() {
  yield all([call(OnFetchIdiotScoresStart)]);
}
