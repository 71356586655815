import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { FaAsterisk } from 'react-icons/fa';

export const Navbar = () => {
  return (
    <Flex>
      <Box className='asterisk'>
        <FaAsterisk size='3em' color='black' />
      </Box>
      <Box className='navbar-title'>FEPO</Box>
      <Box className='navbar-small'>v1.3</Box>
    </Flex>
  );
};
