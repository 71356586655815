import LiveScoresActionTypes from './liveScoresTypes';

const initialState = {
	requesting: false,
	successful: false,
	error: null,
	msg: '',
	liveScores: []
};

const liveScoresReducer = (state = initialState, action) => {
	switch (action.type) {
		case LiveScoresActionTypes.GET_LIVESCORES_START:
			return {
				...state,
				requesting: true,
				successful: false,
				msg: { body: 'Fetching live scores..' }
			};
		case LiveScoresActionTypes.GET_LIVESCORES_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				msg: { body: 'Successfully fetched live scores.' },
				liveScores: action.payload
			};
		case LiveScoresActionTypes.GET_LIVESCORES_FAILURE:
			return {
				...state,
				requesting: false,
				successful: false,
				msg: { body: 'Error fetching live scores.' },
				error: action.payload
			};
		default:
			return state;
	}
};

export default liveScoresReducer;
