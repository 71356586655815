import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { Flex, Box } from '@chakra-ui/react';

export const PickerForm = ({ form }) => {
  const last_nine = form.slice(0, 6);
  return (
    <Flex>
      <Box marginRight='3px'>Last 6: </Box>
      {last_nine.map((recent, id) => (
        <Box marginLeft='3px' key={id}>
          {recent.correct ? (
            <FaCheck color='#41BB17' size='.7em' />
          ) : (
            <ImCross color='#B70606' size='.7em' />
          )}
        </Box>
      ))}
    </Flex>
  );
};
