import React from 'react';
import { Stack, Box, Flex, Spacer, Avatar, Divider } from '@chakra-ui/react';
import { PickerHeader } from './PickerHeader';
import { PickerGames } from './PickerGames';
import { WeeklyRecord } from './WeeklyRecord';

export const IdiotPickerData = ({ scores, week, wins, losses }) => {
  return (
    <Stack>
      <Box>
        <WeeklyRecord scores={scores} week={week} wins={wins} losses={losses} />
      </Box>

      <Box>
        {scores.map((pickers, id) => (
          <Stack
            marginLeft='20px'
            marginTop='30px'
            spacing={0}
            marginBottom='60px'
            key={id}
          >
            <Box marginBottom='10px'>
              <PickerHeader pickers={pickers} />
            </Box>
            <Box width='95%'>
              <Divider borderColor='black' borderWidth='1' opacity='1' />
            </Box>
            <PickerGames games={pickers.games} />
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
