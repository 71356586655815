import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
	GetCorrectScoresFailure,
	GetCorrectScoresSuccess
} from './correctScoresActions';
import CorrectScoresActionTypes from './correctScoresTypes';
import { getCorrectScores } from '../utils/utils';
import { picks } from '../../data/data';

export function* FetchCorrectScoresAsync() {
	yield console.log('FetchCorrectScoresAsync function has fired.');
	try {
		const scores = yield call(getCorrectScores, picks);
		yield console.log(scores);
		yield put(GetCorrectScoresSuccess(scores));
	} catch (e) {
		yield put(GetCorrectScoresFailure(e));
	}
}

export function* OnFetchCorrectScoresStart() {
	yield takeLatest(
		CorrectScoresActionTypes.GET_CORRECTSCORES_START,
		FetchCorrectScoresAsync
	);
	yield console.log(
		'onFetchCorrectScoresStart GET_CORRECTSCORES_START has fired.'
	);
}

export function* correctScoresSagas() {
	yield all([call(OnFetchCorrectScoresStart)]);
}
