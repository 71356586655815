import React from 'react';
import { Stack, Box } from '@chakra-ui/react';

export const AllTime = ({ record }) => {
	const total_wins = record[0].wins + record[1].wins + record[2].wins;
	const total_losses = record[0].losses + record[1].losses + record[2].losses;
	const p = ((total_wins / (total_wins + total_losses)) * 100).toFixed(1);

	return (
		<Stack className='all-time' spacing={-1}>
			<Box>
				All-time record:{' '}
				<span className='purple'>
					{total_wins}-{total_losses}
				</span>
			</Box>
			<Box>
				All-time win rate: <span className='purple'>{p}%</span>
			</Box>
		</Stack>
	);
};
