import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';

export const WeeklyRecord = ({ week, wins, losses, scores }) => {
	const [total_wins, setTotalWins] = useState(0);
	const [total_losses, setTotalLosses] = useState(0);

	const addUpWins = () => {
		let wins = 0;
		let losses = 0;
		scores.map((pickers) => {
			wins = wins + pickers.yearly_wins;
			losses = losses + pickers.yearly_losses;
		});
		setTotalWins(wins);
		setTotalLosses(losses);
	};

	useEffect(() => {
		addUpWins();
	}, []);
	return (
		<Stack marginLeft='20px' spacing={6} marginTop='40px'>
			<Box className='weekly-overall'>
				Overall:{' '}
				<span className='purple'>
					{total_wins}-{total_losses}
				</span>
			</Box>
			<Stack className='weekly-record'>
				<Box>Week {week}</Box>
				<Box>
					Results:{' '}
					<span className='purple'>
						{wins}-{losses}
					</span>
				</Box>
			</Stack>
		</Stack>
	);
};
